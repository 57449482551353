<template>
  <div class="setBox">
    <router-view></router-view>
    <div class="item">
      <router-link to="/set/">
        <div @click="save = 1" :class="{ tion: save === 1 }" class="item1">
          <span class="item1-span">服务项目</span>
        </div>
      </router-link>
      <router-link to="/set/sports" v-if="getTechCommission">
        <div @click="save = 2" :class="{ tion: save === 2 }" class="item1">
          <span class="item1-span">车辆类型</span>
        </div>
      </router-link>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      save:1,
      pathUrl: ["/Setting", "/sports"],
    };
  },
  computed: {
    getTechCommission() {
      return this.$store.state.istechcommission;
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  position: absolute;
  bottom: 24px;
  left: 193px;
  width: 88px;
  height: 68px;
  // background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  // box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  opacity: 1;
  border-radius: 2px;
}
.item1 {
  width: 88px;
  height: 34px;
  box-sizing: border-box;
  text-align: center;
  line-height: 34px;
  background-color: #fff;
}
.tion {
  background-color: #f7f9fb;
}
.item1-span {
  width: 84px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 11px;
  color: #2a3346;
  opacity: 1;
}
</style>